<template>
    <div class="mycollect-page">


        <no-data v-if="activity_list.length==0"></no-data>
        <van-pull-refresh v-model="isloading" success-text="刷新成功" @refresh="onRefresh" v-else>
            <van-list finished-text="加载完毕" :finished="finished" @load="loadData">
                <div class="activity">
                    <div class="activity-item" v-for="(item,index) in activity_list" :key="index">
                        <van-cell title-class="con-cell flex flex-row" @click.stop="toActivity(item.activity_id,index)">
                            <template slot="title">
                                <van-image
                                        width="140px" height="100px"
                                        :src="item.banner_image"
                                        fit="cover"
                                        lazy-load
                                />
                                <div class="text-wrap flex flex-column">
                                    <div class="flex flex-row flex-jusbet">
                                        <span class="t1 text-base font-14 clamp-2 m">{{item.activity_name}}</span>
                                        <p class="status text-red" v-if="item.activity_status == 1">{{item.activity_status_note}}</p>
                                        <p class="status text-green" v-else-if="item.activity_status==2">{{item.activity_status_note}}</p>
                                        <p class="status text-grey" v-else>{{item.activity_status_note}}</p>
                                    </div>

                                    <span class="t2 text-grey font-12 clamp-2">时间：{{item.start_time}}开始</span>

                                    <span class="t2 text-grey font-12 clamp-1"
                                          v-if="item.address">地址：{{item.address}}</span>
                                    <span class="t2 text-grey font-12 clamp-1" v-else>线上活动</span>
                                </div>

                            </template>
                        </van-cell>
                    </div>

                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
    import {
        List,
        Row,
        Col,
        Panel,
        PullRefresh,
        Cell,
        CellGroup,
        Icon
    } from "vant";
    import { user_mycollect,activity_addcollect } from "@/request/api";

    export default {
        components: {
            [List.name]: List,
            [Row.name]: Row,
            [Col.name]: Col,
            [Panel.name]: Panel,
            [PullRefresh.name]: PullRefresh,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Icon.name]: Icon
        },
        data() {
            return {
                page: 1,
                activity_list: [],
                loading: false,
                finished: false,
                isloading:false
            };
        },
        mounted: function () {
            this.loadData();
        },
        methods: {
            async loadData() {
                if(this.finished){
                    return;
                }
                this.loading = true;
                // 获取售票列表
                await user_mycollect({page:this.page}).then(res => {
                    if(res.code == 1){
                        //  还有更多数据page++
                        if (res.data.has_more == true){
                            this.page++;
                            this.finished = false
                        } else {
                            //没有数据了
                            this.finished = true
                        }
                        this.max_count = res.data.max_count;
                        this.activity_list = this.activity_list.concat(res.data.data)
                    } else {
                        this.$dialog({message:res.msg});
                    }
                }).catch(err => {
                    this.$dialog({
                        message: "获取收藏数据失败，请重新刷新"
                    })
                })

                // 加载状态结束
                this.loading = false;
                this.isloading = false;
            },
            onRefresh() {
                this.finished = false;
                this.page = 1;
                this.activity_list = [];
                this.loadData();
            },
            //活动详情页
            toActivity: function (id) {
                this.$router.push({path: "/activity", query: {id: id}});
            },
            //删除收藏
            delCollect(id, index) {
                this.$dialog.confirm({
                    title: '提示',
                    message: '是否取消收藏'
                }).then(() => {
                    let param = {activity_id:id};
                    activity_addcollect(param).then((res)=>{
                        if(res.code==1){
                            this.activity_list.splice(index,1);
                        }else{
                            this.$toast.fail(res.msg);
                        }
                    }).catch((error)=>{
                        this.$toast.fail("操作失败");
                    });
                }).catch(() => {
                    // on cancel
                });
            }
        }
    };
</script>
<style lang="less">
    .mycollect-page {
        margin-bottom: 5px;

        .activity-item {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 0 !important;
            .sponsor {
                padding: 5px 0.42rem !important;
                .van-cell__value {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .top-cell {
                align-items: center;

            }
            .van-icon-cross {
                font-size: 20px;
            }

            .con-cell {
                justify-content: space-between;
                .van-image {

                }
                .text-wrap {
                    flex: 1;
                    margin-left: 10px;
                    justify-content: space-between;
                    .t1 {
                        height: 50px;
                    }
                }
                .status {
                    //   flex: 0 0 45px;
                    min-width: 45px;
                    align-items: flex-start;

                }
            }
        }

    }
</style>
